import { addDays, format as formatDate, isSameDay } from "date-fns";
import PropTypes from "prop-types";
import React, { Component, useState } from "react";
import ReactContentfulImage from "react-contentful-image";

import ClassesApiClient from "@/api/client/entities/classes";
import Close from "@/assets/icons/close-white.svg";
import Loader from "@/components/Loader";
import ImageSizes from "@/utils/helpers/images";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import style from "./index.module.scss";

export class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      scheduleData: [],
      scheduleDataItemCount: 0,
      selectedDay: null,
    };

    this.setSelectedDay = this.setSelectedDay.bind(this);
    this.loadClasses = this.loadClasses.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        selectedDay: new Date(),
      },
      () => {
        const { selectedDay } = this.state;
        this.loadClasses(selectedDay);
      }
    );
  }

  async loadClasses(selectedDay) {
    const { facilityId } = this.props;

    try {
      const startDate = formatDate(selectedDay, "yyyy-MM-dd");
      const endDate = formatDate(addDays(selectedDay, 1), "yyyy-MM-dd");

      const classes = await ClassesApiClient.findAllClassesByFacilityIds(
        [parseInt(facilityId)],
        startDate,
        endDate,
        false
      );

      this.setState({
        classesData: classes,
        loaded: true,
      });
    } catch (error) {
      // Should do something about this globally, commenting the console per the eslint's request.
      console.error(error);
    }
  }

  setSelectedDay(day) {
    this.setState(
      {
        loaded: false,
        selectedDay: day,
      },
      () => {
        this.loadClasses(day);
      }
    );
  }

  render() {
    const schedule = [];
    const daysToLoad = 6;
    const { loaded, selectedDay, classesData } = this.state;
    const { onRequestClose, isDesktop, title } = this.props;

    for (let x = 0; x <= daysToLoad; x++) {
      schedule.push({
        day: addDays(new Date(), x),
      });
    }

    return (
      <div className={style.scheduleWrap}>
        <section className={style.schedule}>
          <button
            className={style.scheduleCloseButton}
            name="ClubDetail:Classes:UIComponent:Schedule - Close"
            onClick={onRequestClose}
          >
            <Close />
          </button>
          <div className={style.scheduleTop}>
            <h2>{title}</h2>
          </div>
          <nav className={style.scheduleNav}>
            <ul className={style.scheduleNavList}>
              {schedule?.map((scheduleItem) => {
                return (
                  <DayButton
                    day={scheduleItem.day}
                    isDesktop={isDesktop}
                    key={scheduleItem.day}
                    selectedDay={selectedDay}
                    setSelectedDay={this.setSelectedDay}
                  />
                );
              })}
            </ul>
          </nav>
          <div className={style.scheduleBody}>
            <div className={`d-lg-none ${style.scheduleBodyDate}`}>
              {selectedDay ? formatDate(selectedDay, "MMMM d") : ""}
            </div>
            {loaded && classesData && (
              <ul className={style.scheduleBodyList}>
                {classesData.map((classItem) => {
                  return (
                    <ClassListing data={classItem} key={classItem?.name} />
                  );
                })}
              </ul>
            )}
            {classesData && classesData.length === 0 ? (
              <div className={style.classNoClasses}>
                <span>No Classes Found</span>
              </div>
            ) : null}
            {!loaded && (
              <div className={style.scheduleBodyLoading}>
                <Loader color="dark" />
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default withScreenDimensions(Schedule);

Schedule.propTypes = {
  facilityId: PropTypes.number,
  isDesktop: PropTypes.bool,
  onRequestClose: PropTypes.func,
  title: PropTypes.string,
};

const ClassListing = ({ data }) => {
  const {
    name,
    instructors,
    displayTime,
    facility: { webName },
    mobileImageURL,
    classInstanceId,
  } = data;

  return (
    <a href={`/groupfitness/${classInstanceId}`}>
      <li className={style.class}>
        <div className={style.classImage}>
          {mobileImageURL ? (
            <ReactContentfulImage
              alt={name}
              sizes={ImageSizes.classIcon}
              src={mobileImageURL}
              title={name}
            />
          ) : (
            <div className={style.classImageEmpty} />
          )}
        </div>
        <div className={style.className}>
          <h4>{name}</h4>
        </div>
        <div className={style.classInstructor}>
          {instructors &&
            instructors.map(({ instructor, substitute }) => {
              const { firstName, lastName } = instructor;

              return (
                <div key={`${firstName}-${lastName}`}>
                  {substitute ? (
                    <>
                      <span>
                        {substitute.firstName} {substitute.lastName}
                      </span>
                      <strong>SUB</strong>
                    </>
                  ) : (
                    <span>
                      {firstName} {lastName}
                    </span>
                  )}
                </div>
              );
            })}
        </div>
        <div className={style.classTime}>
          <span>{displayTime}</span>
        </div>
        <div className={style.classLocation}>
          <span>{webName}</span>
        </div>
      </li>
    </a>
  );
};

ClassListing.propTypes = {
  data: PropTypes.object,
};

const DayButton = ({ day, selectedDay, setSelectedDay, isDesktop }) => {
  const [hoveredDay, setHoveredDay] = useState("");

  if (!day || !selectedDay) {
    return null;
  }

  const isToday = isSameDay(new Date(), day);
  const isActive = isSameDay(day, selectedDay);

  const name = () => {
    let value = "";

    if (!isDesktop) {
      value = formatDate(day, "EEEEE");
    } else if (hoveredDay === day && !isToday) {
      value = formatDate(day, "E, MMM dd");
    } else if (isToday) {
      value = "Today";
    } else if (isActive) {
      value = formatDate(day, "E, MMM dd");
    } else {
      value = formatDate(day, "E");
    }

    return value;
  };

  const classes = isActive ? style.itemActive : "";

  return (
    <li className={classes}>
      {isDesktop ? (
        <button
          name="ClubDetail:Classes:UIComponent:Schedule - Select Day"
          onClick={() => setSelectedDay(day)}
          onMouseEnter={() => setHoveredDay(day)}
          onMouseLeave={() => setHoveredDay("")}
        >
          {name()}
        </button>
      ) : (
        <span onClick={() => setSelectedDay(day)}>{name()}</span>
      )}
    </li>
  );
};

DayButton.propTypes = {
  day: PropTypes.object,
  isDesktop: PropTypes.bool,
  selectedDay: PropTypes.object,
  setSelectedDay: PropTypes.func,
};
