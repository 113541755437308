import get from "lodash/get";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";

import EditorialTitle from "@/components/EditorialTitle";
import Schedule from "@/components/club-detail/classes/ui-components/schedule";
import ThreeColumnExpander from "@/components/club-detail/classes/ui-components/three-column-expander";
import ModalWrap from "@/components/modal-wrap";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { CLASS_SCHEDULE_DOWNLOAD_PATH } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import ImageSizes from "@/utils/helpers/images";
import ScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./index.module.scss";

class OpenClub extends PureComponent {
  static contextType = DigitalDataLayerContext;

  constructor(props) {
    super(props);

    this.state = {
      classesData: null,
      loaded: null,
      modalOpen: false,
      selectedClass: null,
    };

    this.selectClass = this.selectClass.bind(this);
    this.openClassSchedule = this.openClassSchedule.bind(this);
    this.downloadClassScheduleUrl = "";
    this.handleAcdlEvent = this.handleAcdlEvent.bind(this);
  }

  async componentDidMount() {
    const { classes, facilityId } = this.props;

    try {
      if (facilityId) {
        this.downloadClassScheduleUrl = `${CLASS_SCHEDULE_DOWNLOAD_PATH}/${facilityId}/pdf`;
      }

      this.setState({
        classesData: classes,
        loaded: true,
        selectedClass: classes.classCategory[0].classInstance.classInstanceId,
      });
    } catch (error) {
      // Should do something about this globally, commenting the console per the eslint's request.
      console.error(error);
    }
  }

  handleAcdlEvent() {
    const { router } = this.props;
    const { setNewDataLayer } = this.context;

    setNewDataLayer(
      ACDL_GLOBAL_TRACKING.classScheduleDownloadUrl(router.asPath)
    );
  }

  selectClass(classID) {
    const { selectedClass } = this.state;

    if (selectedClass === classID) {
      return this.setState({ selectedClass: null });
    }

    this.setState({ selectedClass: classID });
  }

  renderHeadlineSection() {
    const { club, facilityId } = this.props;
    const { classesData } = this.state;

    if (!classesData) {
      return <div />;
    }

    return (
      <>
        <div className={styles.upcomingClassesTop}>
          <h3 className={styles.classesHeadline}>Classes this week</h3>

          {club.subcopy && (
            <p className={styles.classesDescription}>
              {club.subcopy.fields.subcopy}
            </p>
          )}
        </div>

        <div className={styles.classesCtas}>
          <button
            className={styles.viewClassSchedule}
            name="ClubDetail:Classes:UIComponents:OpenClub - View Class Schedule"
            onClick={this.openClassSchedule}
            type="button"
          >
            View Class Schedule
          </button>

          {facilityId &&
            this.downloadClassScheduleUrl &&
            this.downloadClassScheduleUrl.length > 0 && (
              <a
                className={styles.downloadClassSchedule}
                href={this.downloadClassScheduleUrl}
                onClick={this.handleAcdlEvent}
                rel="noreferrer"
                target="_blank"
              >
                Download Class Schedule
              </a>
            )}
        </div>
      </>
    );
  }

  renderClasses() {
    const { classesData } = this.state;

    if (!classesData) {
      return <div />;
    }

    return classesData.classCategory.map((row) => {
      const { workoutCategoryName, classInstance } = row;

      return (
        <ThreeColumnExpander
          category={workoutCategoryName}
          description={classInstance.classDescription}
          featuredTag={classInstance.isNew ? "New" : ""}
          id={classInstance.classInstanceId}
          key={classInstance.classInstanceId}
          selectClass={this.selectClass}
          selectedClass={this.state.selectedClass}
          title={classInstance.className}
        />
      );
    });
  }

  openClassSchedule() {
    if (this.props.handleClassScheduleAcdlEvent) {
      this.props.handleClassScheduleAcdlEvent();
    }

    this.setState({ modalOpen: true });
  }

  /*
   * On card hover the parent background fades to asscoiated image
   */
  renderBackgroundImage() {
    const { selectedClass, classesData } = this.state;

    if (!classesData) {
      return null;
    }

    return (
      <div className={styles.backgroundImageContainer}>
        {classesData.classCategory.map((slide) => {
          const key = `${slide.classInstance.classInstanceId}-image`;
          const desktopImage =
            get(slide, "classInstance.image", null) ||
            get(slide, "categoryImage", null) ||
            get(slide, "classInstance.mobileImage", null) ||
            get(slide, "categoryMobileImage", null);
          const mobileImage =
            get(slide, "classInstance.mobileImage", null) ||
            get(slide, "categoryMobileImage", desktopImage);

          return (
            <React.Fragment key={key}>
              {desktopImage && (
                <div
                  aria-hidden={
                    selectedClass !== slide.classInstance.classInstanceId
                  }
                  className={`${styles.backgroundImage} ${styles.backgroundImageDesktop}`}
                  style={{
                    backgroundImage: `url(${
                      (desktopImage.indexOf("//") === 0 ? "https:" : "") +
                      desktopImage
                    }?${ImageSizes.fullScreenBackgroundDesktop()})`,
                  }}
                />
              )}
              {mobileImage && (
                <div
                  aria-hidden={
                    selectedClass !== slide.classInstance.classInstanceId
                  }
                  className={`${styles.backgroundImage} ${styles.backgroundImageMobile}`}
                  style={{
                    backgroundImage: `url(${
                      (mobileImage.indexOf("//") === 0 ? "https:" : "") +
                      mobileImage
                    }?${ImageSizes.fullScreenBackgroundMobile()})`,
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      club: { editorialTitle, title },
      clubType,
      isMobile,
      isDesktop,
      facilityId,
    } = this.props;
    const { loaded, modalOpen } = this.state;
    const titleTweenFrom = {
      css: {
        opacity: !isDesktop ? 1 : 0,
        top: !isDesktop ? "0px" : "250px",
      },
      ease: "Strong.easeInOut",
    };
    const titleTweenTo = {
      css: { opacity: 1, top: "0px" },
      ease: "Strong.easeInOut",
    };

    const classesTweenFrom = {
      css: {
        opacity: !isDesktop ? 1 : 0,
        position: "relative",
        top: !isDesktop ? "0px" : "250px",
      },
      ease: "Strong.easeInOut",
    };
    const classesTweenTo = {
      css: { opacity: 1, top: "0px" },
      ease: "Strong.easeInOut",
    };

    return (
      <section className={`${styles.upcomingClasses}`} id="open-club">
        {this.renderBackgroundImage()}

        <div className={styles.content}>
          <Controller key={`${isMobile}-${isDesktop}`}>
            <Scene duration="30%" triggerElement={`#open-club`}>
              {(progress) => {
                return (
                  <Tween
                    from={titleTweenFrom}
                    paused
                    to={titleTweenTo}
                    totalProgress={progress}
                  >
                    <div className={styles.classHeader}>
                      {editorialTitle ? (
                        <EditorialTitle
                          clubType={clubType}
                          data={editorialTitle}
                        />
                      ) : (
                        <>{this.renderHeadlineSection()}</>
                      )}
                    </div>
                  </Tween>
                );
              }}
            </Scene>
            <Scene duration="40%" triggerElement="#open-club">
              {(progress) => {
                return (
                  <Tween
                    from={classesTweenFrom}
                    paused
                    to={classesTweenTo}
                    totalProgress={progress}
                  >
                    <div className={styles.classList}>
                      {this.renderClasses()}
                    </div>
                  </Tween>
                );
              }}
            </Scene>
          </Controller>
        </div>

        {loaded && facilityId && (
          <ModalWrap
            marginTop={15}
            onRequestClose={() => {
              this.setState({ modalOpen: false });
            }}
            open={modalOpen}
          >
            <Schedule
              facilityId={parseInt(facilityId)}
              onRequestClose={() => {
                this.setState({ modalOpen: false });
              }}
              title={title}
            />
          </ModalWrap>
        )}
      </section>
    );
  }
}

OpenClub.propTypes = {
  classes: PropTypes.object,
  club: PropTypes.object,
  clubType: PropTypes.string,
  facilityId: PropTypes.string.isRequired,
  handleClassScheduleAcdlEvent: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  router: PropTypes.object,
};

export default ScreenDimensions(withRouter(OpenClub));
